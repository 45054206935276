import React, {useEffect,useState}  from 'react';
import GConf from '../../AssetsM/generalConf';
import { _ } from "gridjs-react";
import axios from 'axios';
import { Fade } from 'react-reveal';
import SKLT from '../../AssetsM/Cards/usedSlk';
import TableGrid from '../../AssetsM/Cards/tableGrid';
import SubNav from '../../AssetsM/Cards/subNav';
import GoBtn from '../../AssetsM/Cards/goBtn';
import TableImage from '../../AssetsM/Cards/tableImg';
import { toast } from 'react-toastify';
import { Button , Dropdown, Icon, Input, Modal, Tab, Form, TextArea } from 'semantic-ui-react';
import { useNavigate} from 'react-router-dom';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import CountUp from 'react-countup';

const CustomTabs = ({activeIndex, setActiveIndex, rechercheDay, setRechercheDay, SeachFinanceFunction}) => {
    return(<>
      <div className='row mb-3'>
        <div className='col-12 col-lg-8 align-self-center' >
          <div style={{width:'100%', overflowX: 'auto', overflowY : 'hidden',  whiteSpace:'nowrap'}}>
            <Menu secondary >
                <Menu.Item active={activeIndex == 0} className='rounded-pill' onClick={ () => setActiveIndex(0)}>
                  <span className='text-primary'>
                    <b>
                      <span className='bi bi-wallet2'></span> Budget
                    </b>
                  </span>
                </Menu.Item>
                <Menu.Item active={activeIndex == 1} className='rounded-pill' onClick={ () => setActiveIndex(1)}>
                  <span className='text-success'>
                    <b>
                      <span className='bi bi-caret-up-fill'></span> Revenue
                    </b>
                  </span>
                </Menu.Item>
                <Menu.Item active={activeIndex == 2} className='rounded-pill' onClick={ () => setActiveIndex(2)}>
                  <span className='text-danger'>
                    <b>
                      <span className='bi bi-caret-down-fill'></span> Depenses
                    </b>
                  </span>
                </Menu.Item>
                <Menu.Item active={activeIndex == 3} className='rounded-pill' onClick={ () => setActiveIndex(3)}>
                  <span className='text-success'>
                    <b>
                      <span className='bi bi-plus-circle-fill'></span> Revenue Caisses
                    </b>
                  </span>
                </Menu.Item>
                <Menu.Item active={activeIndex == 4} className='rounded-pill' onClick={ () => setActiveIndex(4)}>
                  <span className='text-secondary'>
                    <b>
                      <span className='bi bi-piggy-bank'></span> Ajouter 
                    </b>
                  </span>
                </Menu.Item>
            </Menu>
          </div>
        </div>
        <div className='col-12 col-lg-4 align-self-center' >
                  <div className='mb-2 row'>
                      <div className='col-5 col-lg-5'><Input size='small' fluid  type='date' defaultValue={rechercheDay.start} onChange={(e) => setRechercheDay({...rechercheDay, start: e.target.value })} /></div>
                      <div className='col-5 col-lg-5'><Input size='small' fluid  type='date' defaultValue={rechercheDay.end} onChange={(e) => setRechercheDay({...rechercheDay, end: e.target.value })} /></div>
                      <div className='col-2 col-lg-2'><Button fluid className='btn-imprimer' size='small' icon onClick={ () => SeachFinanceFunction(rechercheDay)} >  <Icon name='search' />   </Button></div>
                  </div>
        </div>
      </div>
    </>)
  }

const AddAuxBudgetCard = () => {
    let [addAssetData, setAddAssetDta] = useState({FI_Date : '', FI_Decription:'', FI_Genre:'INPUT'})
    const selectList = [
        {id:1, value:'pigpmiup', text:'mihybgliygip'}
    ]
    return(<> 
            <div className='row'>
                <div className='col-12 col-lg-6 '>
                   <div className='row'>
                        <div className='col-6'>
                            <div className={`card p-2 shadow-sm border-div mb2 text-center ${addAssetData.FI_Genre == 'INPUT' ? 'border-3 border-success  text-success' : 'text-secondary'} `} style={{cursor: "pointer"}} onClick={() => setAddAssetDta({...addAssetData, FI_Genre: 'INPUT' })} > <b>Ajouter Revenue</b> </div>
                        </div>
                        <div className='col-6'>
                            <div className={`card p-2 shadow-sm border-div mb2 text-center ${addAssetData.FI_Genre == 'OUTPUT' ? 'border-3 border-danger text-danger' : 'text-secondary'} `} style={{cursor: "pointer"}} onClick={() => setAddAssetDta({...addAssetData, FI_Genre: 'OUTPUT' })} > <b>Ajouter Depense</b> </div>
                        </div>
                   </div> 
                   <h5 className='mb-1'>Asset Genre</h5>
                   <Dropdown fluid clearable options={selectList} selection />

                   <h5 className='mb-1'>Description </h5>
                   <Form>
                        <TextArea placeholder='Tell us more' />
                    </Form>

                    <h5 className='mb-1'>Date</h5>
                    <Input icon='birthday cake' type='date' placeholder='Camion'  iconPosition='left'   fluid className='shadow-sm' defaultValue={new Date().toISOString().split('T')[0]}  /> 

                    <div className='text-end mt-2'>
                        <Button className='rounded-pill btn-system' >Enregistrer</Button>
                    </div>
                </div>
                <div className='col-12 col-lg-6 align-self-center'>
                    <img src={`https://cdn.abyedh.com/Images/system/wallet-${addAssetData.FI_Genre == 'INPUT' ? 'in' : 'out'}.svg`} className='img-responsive' width='70%' />
                </div>
            </div>
    </>)
}

function FinancePage() {
    /*#########################[Const]##################################*/
    const Today = new Date().toISOString().split('T')[0];
    const LastWeek = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0]
    let [reservationList, setReservationList] = useState([SKLT.TableSlt]); 
    let [budgetListe, setBudgetList] = useState([SKLT.TableSlt]); 
    let [revenueListe, setRevenueListe] = useState([SKLT.TableSlt]); 
    let [depensesListe, setDepensesListe] = useState([SKLT.TableSlt]); 
    let [revenueSpecListe, setRevenueSpecListe] = useState([SKLT.TableSlt]); 
    const [activeIndex, setActiveIndex] = useState(0)
    const [rechercheDay, setRechercheDay] = useState({start:LastWeek, end:Today}); 
    
    let [revenueTot, setRevenueTot] = useState(0); 
    let [depensesTot, setDepensesTot] = useState(0); 
    let [revenueSpecTot, setRevenueSpecTot] = useState(0);
    
    const [modalS, setModalS] = useState(false)
    const [selectedArticle, setSelectedArticle] = useState([])

    
    const navigate = useNavigate();
    
    const panesRes = [
      {
        menuItem: { key: 'attent',  content: <span className='text-warning'><b><span className='bi bi-hourglass-split'></span> En Attent</b></span> , className:'rounded-pill'},
        render: () => <>    
                            <div className='row mt-5'>
                                <div className='col-7'><DisplayResumeCard /> </div>
                                <div className='col-5'><TableGrid tableData={budgetListe} columns={['*', 'Date','Valeur','Genre' ]} /></div>
                            </div>
                        </>,
      },
      {
        menuItem: { key: 'attent',  content: <span className='text-warning'><b><span className='bi bi-hourglass-split'></span> En Attent</b></span> , className:'rounded-pill'},
        render: () => <TableGrid tableData={revenueListe} columns={['*','ID','Date','Valeur','Genre', 'description','modifier']} />,
      },
      {
        menuItem: { key: 'accept',  content: <span className='text-success'><b><span className='bi bi-check-square-fill'></span> Accepteé</b></span> , className:'rounded-pill' },
        render: () => <TableGrid tableData={depensesListe} columns={['*','ID','Date','Valeur','Genre', 'description','modifier']} />,
      },
      {
        menuItem: { key: 'refuse',  content: <span className='text-danger'><b><span className='bi bi-x-square-fill'></span> Refuseé</b></span>, className:'rounded-pill' },
        render: () => <TableGrid tableData={revenueSpecListe} columns={['*','ID','Date','Valeur','Genre','Genre', 'valeur','Etat']} />,
      },
      {
        menuItem: { key: 'refuse',  content: <span className='text-danger'><b><span className='bi bi-x-square-fill'></span> Refuseé</b></span>, className:'rounded-pill' },
        render: () => <AddAuxBudgetCard />,
      },
    ]

   /*#########################[UseEfeect]##################################*/
    useEffect(() => {
        SeachFinanceFunction(rechercheDay) 
    }, [])
    
   /*#########################[Function]##################################*/
    const NavigateFunction = (link) => {  navigate(link) }

    const openEditModal = (event,selected) =>{
        //RemplirCalendarEvent()
        setSelectedArticle(event)
        setModalS(true)
    }
    const SeachFinanceFunction = (targetDay) => {
        axios.post(`${GConf.SharedApi}/finance`, {
            PID : GConf.PID,
            targetDay : targetDay,
         })
         .then(function (response) {
            let revenue = response.data.filter((data) => data.FI_Genre == 'INPUT')
            let depenses = response.data.filter((data) => data.FI_Genre == 'OUTPUT')

            let commandeContainer = []
            response.data.map( (commandeDate) => commandeContainer.push([          
                _(<TableImage forStock image='finance.png' />),
                commandeDate.FI_Value,
                _(<span className={`bi bi-caret-${commandeDate.FI_Genre == 'INPUT' ? 'up-fill text-success' : 'down-fill text-danger'}`}></span>),
                new Date(commandeDate.FI_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ),
            ],))
            setBudgetList(commandeContainer)


            let revenueContainer = []
            revenue.map( (commandeDate) => revenueContainer.push([          
                _(<TableImage forStock image='up.png' />),
                commandeDate.FI_ID,
                commandeDate.FI_Value,
                commandeDate.FI_Genre,
                new Date(commandeDate.FI_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ),
                 commandeDate.FI_Decription,
                _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(`/S/rq/info/${commandeDate.Fi_ID}`)}><span className='d-none d-lg-inline'> Mofifier </span><Icon  name='angle right' /></Button>)
            ],))
            setRevenueListe(revenueContainer)

            let depensesContainer = []
            depenses.map( (commandeDate) => depensesContainer.push([          
                _(<TableImage forStock image='down.png' />),
                commandeDate.FI_ID,
                commandeDate.FI_Value,
                commandeDate.FI_Genre,
                new Date(commandeDate.FI_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ),
                 commandeDate.FI_Decription,
                _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(`/S/rq/info/${commandeDate.Fi_ID}`)}><span className='d-none d-lg-inline'> Mofifier </span><Icon  name='angle right' /></Button>)
            ],))
            setDepensesListe(depensesContainer)

            let calculateRevTot = 0
            revenue.map((data) => calculateRevTot += data.FI_Value)
            setRevenueTot(calculateRevTot)

            let calculateDepTot = 0
            depenses.map((data) => calculateDepTot += data.FI_Value)
            setDepensesTot(calculateDepTot)


         }).catch((error) => {
           if(error.request) {
             toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de Charger La Liste de  Commandes  </div></>, GConf.TostInternetGonf)   
             setReservationList([])
           }
         });

         axios.post(`${GConf.ApiLink}/facture/resumer`, {
            PID : GConf.PID,
            targetDate: targetDay,
        })
        .then(function (response) {
            let factureListContainer = []
            response.data.map( (getData) => factureListContainer.push([
            _(<TableImage image='facture.jpg' />),
            getData.T_ID,
            getData.CA_Name,
            getData.CL_Name,
            new Date(getData.T_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ),
            getData.T_Time,
            getData.Final_Value,
            _(<StateCard status={getData.Pay_State} />),
            
            ],))
            setRevenueSpecListe(factureListContainer)

            let calculateTot = 0
            response.data.map((data) => calculateTot += data.Final_Value)
            setRevenueSpecTot(calculateTot)
            
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de charger les factures   </div></>, GConf.TostInternetGonf)   
              setRevenueSpecListe([])
               
            }
        });

    }
    /*#########################[Card]##################################*/
 
    const StateCard = ({ status }) => {
        const StateCard = (props) =>{ return <span className={`badge bg-${props.color}`}> {props.text} </span>}
        const statusCard = React.useCallback(() => {
          switch(status) {
            case 'Payee': return <StateCard color='success' text='Payeé' />;  
            case 'Credit': return <StateCard color='danger' text='Credit' /> ;
            case 'Waitting': return <StateCard color='warning' text='En Attend' /> ;
            default:  return <StateCard color='secondary' text='Indefinie' />;    
          }
        }, [status]);
      
        return (
          <div className="container">
            {statusCard()}
          </div>
        );
    };
    const DisplayResumeCard = () => {
        return(<>
           <div className='row'>
                <div className='col-6'><div className='card card-body shadow-sm border-div mb-2'><small>Revenue & Revenue Caisse </small><h2 className='text-success text-center mt-2'><CountUp end={revenueTot+revenueSpecTot} decimals={3} decimal="," duration={0.5} /></h2></div></div>
                <div className='col-6'><div className='card card-body shadow-sm border-div mb-2'><small>Depenses</small><h2 className='text-danger text-center mt-2'><CountUp end={depensesTot} decimals={3} decimal="," duration={0.5} /></h2></div></div>
                <div className='col-12'><div className='card card-body shadow-sm border-div mb-2'><small>Nette </small><h2 className='text-info text-center mt-2'><CountUp end={(revenueTot+revenueSpecTot) - depensesTot} decimals={3} decimal="," duration={0.5} /></h2></div></div>
           </div>
           <NavLink to='stat' >Voir Chart</NavLink>
        </>)
    }
    
    return (<>
            
        <Fade>
                  <CustomTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex} rechercheDay={rechercheDay} setRechercheDay={setRechercheDay} SeachFinanceFunction={SeachFinanceFunction} /> 
                   
                  <Tab menu={{ secondary: true }} activeIndex={activeIndex} panes={panesRes}  className='no-menu-tabs mt-2' />
        </Fade>
        <Modal
              size='small'
              open={modalS}
              closeIcon
              onClose={() => setModalS(false)}
              onOpen={() => setModalS(true)}
          >
              <Modal.Header><h4> Calendrier des reservation </h4></Modal.Header>
              <Modal.Content scrolling>

                      <table className='table table-striped'>
                          <thead>
                              <tr>
                              <th scope="col">No</th>
                              <th scope="col">Designiation</th>
                              <th scope="col">Qté</th>
                              <th scope="col">PUHT</th>
                              <th scope="col">PUTTC</th>
                              <th scope="col">Prix Net</th>
                              </tr>
                          </thead>
                          <tbody>
                          {
                              selectedArticle.Articles ? 
                              <>
                              {
                                  JSON.parse(selectedArticle.Articles).map( (data,index) => 
                                      <tr key={index +1 }>
                                          <th scope="row">{index +1 }</th>
                                          <td>{data.Name}</td>
                                          <td>{data.Qte}</td>
                                          <td>{GConf.DefaultTva} %</td>
                                          <td>{data.Prix ? data.Prix.toFixed(3) : ''}</td>
                                          <td>{data.PU}</td>
                                      </tr>
                                  )
                              }
                              </>
                              :
                              <>
                              </>
                          }
                          </tbody>
                      </table> 
              </Modal.Content>
              <Modal.Actions>
                          <Button className='rounded-pill' negative onClick={ () => setModalS(false)}> <span className='bi bi-x' ></span> Fermer</Button>
                          <Button className='rounded-pill bg-system-btn'   onClick={ (e) => NavigateFunction(`/S/rq/info/${selectedArticle.C_ID}`)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>
              </Modal.Actions>
        </Modal>
    </>);
}

export default FinancePage;