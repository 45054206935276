import React, {useEffect,useRef,useState}  from 'react';
import TableGrid from '../../../AssetsM/Cards/tableGrid';
import BackCard from '../Assets/Cards/backCard';
import OneGConf from '../Assets/OneGConf';
import GConf from '../../../AssetsM/generalConf';
import { _ } from "gridjs-react";
import axios from 'axios';
import TableImage from '../../../AssetsM/Cards/tableImg';
import GoBtn from '../../../AssetsM/Cards/goBtn';
import SKLT from '../../../AssetsM/Cards/usedSlk';
import { Label, Modal } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';
import { useNavigate} from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { QrReader } from 'react-qr-reader';

function ClientList() {
    /*################[Variable]###############*/
    const inputRef = useRef(null);
    const  [clientList, setClientList] = useState([SKLT.STableSlt]); 
    const navigate = useNavigate();
    const [openD, setOpenD] = useState(false)
    const [keyBordI, setKeyBoedI] = useState('')
    const [floatOpen, setFloatOpen] = useState(false)
    const [scanResultCommande, setScanResultCommande] = useState(false);

    const colors = [
        'red',
        'orange',
        'yellow',
        'olive',
        'green',
        'teal',
        'blue',
        'violet',
        'purple',
        'pink',
        'brown',
        'grey',
        'black',
    ]

    /*################[UseEffect]###############*/
    useEffect(() => {
        axios.post(`${GConf.ApiLink}/commande`,{
            PID : OneGConf.forPID.PID,
        })
        .then(function (response) {
        
            let testTable = []
            response.data.map( (getData) => testTable.push([
                _(<TableImage forStock image='commande.png'/>),
                getData.R_ID,
                getData.Name,
                new Date(getData.R_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ),
                new Date(getData.Wanted_Day).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ),
                getData.Wanted_Time,
                _(<StateCard status={getData.State} />),
                // _( <a  className='data-link-modal'  onClick={() => openEditModal(getData,true)} ><b> <span className='bi bi-arrows-fullscreen'></span> </b></a>),
                _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/C/L/cmd/info/${getData.R_ID}`)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
            ],))
            setClientList(testTable)
        })
    }, [])

    /*#########################[Function]##################################*/
    const NavigateFunction = (link) => {  navigate(link) }
    const  capitalizeFirstLetter = (string) =>{
        return (string.charAt(0).toUpperCase() + string.slice(1)).charAt(0);
    }

    const AddValue = (wantedValue) => {
        const searchInput = document.querySelector('.gridjs-search-input');
        if (searchInput) {
          searchInput.value = wantedValue; // Set your default search value here
          searchInput.dispatchEvent(new Event('input')); // Trigger the search programmatically
        }
    }

    const BtnClicked = (value) =>{
        //socket.emit('cmd-saved', {message: value})
        if (value === 'C') { 
            setKeyBoedI('') 
            //alert(inputRef.current.value)
            inputRef.current.value = ''
            inputRef.current.focus()
        } 
        else if (value === '.' ) { 
            //setKeyBoedI(parseFloat(keyBordI)) 
            //alert(parseFloat(keyBordI))
            setFloatOpen(true)
        } 
        else {
            //setKeyBoedI(Number(keyBordI+value)) 
             
            if (floatOpen) {
                setKeyBoedI(prevInput => prevInput + '.' + parseFloat(value))
                setFloatOpen(false)
            } else {
                setKeyBoedI(prevInput => prevInput + parseFloat(value))
            }
        }
    }

    /*################[Card]###############*/
    const AvatarCard = (props) =>{
        return(<>
                <Label size='massive' circular color={colors[Math.floor(Math.random() * 10)]} key={1}>
                    <h3>{props.lettre}</h3>
                </Label>
                
            </>)
    }

    const StateCard = ({ status }) => {
        const StateCard = (props) =>{ return <span className={`badge bg-${props.color}`}> {props.text} </span>}
        const statusCard = React.useCallback(() => {
          switch(status) {
            case 'W': return <StateCard color='warning' text='En Attent' />;  
            case 'S': return <StateCard color='info' text='Vu' />;  
            case 'A': return <StateCard color='success' text='Acepteé' /> ;
            case 'R': return <StateCard color='danger' text='Refuseé' />;
            case 'F': return <StateCard color='secondary' text='Termineé' />;
            default:  return <StateCard color='dark' text='Indefinie' />;    
          }
        }, [status]);
      
        return (
          <div className="container">
            {statusCard()}
          </div>
        );
    };
    const MainSubNavCard = (props) =>{
        return(<>
           <NavLink exact='true' to={`${props.link}`} className='card card-body mb-1 rounded-pill shadow-sm d-inline-block ' >
            <h4 style={{color : GConf.themeColor}}> <spn className={`bi bi-${props.icon} me-1 `}></spn>{props.text}</h4>
          </NavLink>
        </>) 
    }

    const ClavierCard = () =>{
        const BtnCard = (props) =>{
            return(<>
                <Button className={`shadow-sm ${props.bg == true ? 'bg-danger text-white ' : OneGConf.themeMode == 'dark' ? 'bg-dark-theme-3 text-white border-dark' : 'bg-white' } border mb-1 border-div `} style={{width:'100%', height:'60px', backgroundColor:'red'}} onClick={(e) => BtnClicked(props.value) } ><h2>{props.value}</h2></Button>
            </>)
        }
        return(<>
            <div className='row '>
                <div className='col-4 p-2'><BtnCard value='1' /></div>
                <div className='col-4 p-2'><BtnCard value='2' /></div>
                <div className='col-4 p-2'><BtnCard value='3' /></div>
                <div className='col-4 p-2'><BtnCard value='4' /></div>
                <div className='col-4 p-2'><BtnCard value='5' /></div>
                <div className='col-4 p-2'><BtnCard value='6' /></div>
                <div className='col-4 p-2'><BtnCard value='7' /></div>
                <div className='col-4 p-2'><BtnCard value='8' /></div>
                <div className='col-4 p-2'><BtnCard value='9' /></div>
                <div className='col-4 p-2'><BtnCard value='0' /></div>
                <div className='col-4 p-2'><BtnCard value='.' bg={floatOpen} /></div>
                <div className='col-4 p-2'><BtnCard value='C' /></div>
            </div>
        </>)
    }

    return ( <>
        <div className={`${OneGConf.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`} style={{height: '100vh', overflow: 'scroll'}}>
            <BackCard data={OneGConf.backCard.clList}/>
            <br />
            <br />
            <div className='container'>
                <div className='row'>
                    {/* <div className='col-12 col-lg-8'><MainSubNavCard text='Accepter' link='/C/L/cmd/accept' icon='bounding-box-circles' />  </div> */}
                    <div className='col-12 col-lg-6 text-start align-self-center'><Button size='meduim' onClick={() => setOpenD(!openD)}> Trouver Une Commande </Button>  </div>
                    <div className='col-12 col-lg-3 text-end align-self-center'>{keyBordI}  </div>
                    <div className='col-12 col-lg-3 text-end align-self-center'><Button size='meduim' onClick={() => AddValue(keyBordI)}> Recherche </Button>  </div>
                </div>
                <br />
                <TableGrid dark={true} tableData={clientList} columns={['*','ID','Client', 'Date','Date','Table','Etat', 'Voir']} />
            </div>
        </div>
        <BottomSheet expandOnContentDrag open={openD}  onDismiss={() => setOpenD(!openD)}  >
                <div className='container'>
                     
                    <div className='row mt-0'>
                        <div className='col-12 col-md-5'>
                            <div className='mb-2'>
                                <QrReader
                                        constraints={{  facingMode: 'environment' }}
                                        scanDelay={500}
                                        onResult={(result, error) => {
                                        if (!!result) {  setKeyBoedI(result.text)  }
                                        if (!!error) { console.log(error);  }
                                        }}
                                        style={{  width: "150px",height: "150px" }}
                                />
                                
                                
                                 
                                <br />
                                
                                
                            </div>
                        </div>
                        <div className='col-12 col-md-7 align-self-center'> 
                            <h2><span className='bi bi-keyboard'></span>  : {keyBordI}</h2>
                            <ClavierCard /> 
                            {/* <Button onClick={() =>  { AddValue(keyBordI) }} >Ajouter Valeur </Button> */}
                        </div>
                    </div> 
                </div>
        </BottomSheet>
        {/* <Modal
              size='small'
              open={openD}
              closeIcon
              onClose={() => setOpenD(false)}
              onOpen={() => setOpenD(true)}
          >
              <Modal.Header><h4> Liste des Ordres </h4></Modal.Header>
              <Modal.Content scrolling>
              <div className='card-body'>
                     
                    <div className='row mt-0'>
                        <div className='col-12 col-md-5'>
                            <div className='mb-2'>
                                <QrReader
                                        constraints={{  facingMode: 'environment' }}
                                        scanDelay={500}
                                        onResult={(result, error) => {
                                        if (!!result) {  AddValue(result.text); setScanResultCommande(false) }
                                        if (!!error) { console.log(error);  }
                                        }}
                                        style={{  width: "150px",height: "150px" }}
                                />
                                
                                
                                 
                                <br />
                                
                                
                            </div>
                        </div>
                        <div className='col-12 col-md-7 align-self-center'> 
                            <h2><span className='bi bi-keyboard'></span>  : {keyBordI}</h2>
                            <ClavierCard /> 
                            <Button onClick={() =>  { AddValue(keyBordI) }} >Ajouter Valeur </Button>
                        </div>
                    </div> 
                </div>
                     
              </Modal.Content>
              <Modal.Actions>
                          <Button className='rounded-pill' negative onClick={ () => setOpenD(false)}> <span className='bi bi-x' ></span> Fermer</Button>
              </Modal.Actions>
        </Modal> */}
        </> );
}

export default ClientList