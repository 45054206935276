import React , { useEffect, useState } from 'react';
import BackCard from '../Assets/Cards/backCard';
import OneGConf from '../Assets/OneGConf';
import {Grid, _ } from "gridjs-react";
import { Button, Icon, Menu } from 'semantic-ui-react';
import TableGrid from '../../../AssetsM/Cards/tableGrid';
import axios from 'axios';
import GConf from '../../../AssetsM/generalConf';
import TableImage from '../../../AssetsM/Cards/tableImg';
import { toast } from 'react-toastify';
import { useNavigate} from 'react-router-dom';
import SKLT from '../../../AssetsM/Cards/usedSlk';
import { useParams } from 'react-router-dom';
import { Input } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

function StockList() {
  /*#########################[Const]##################################*/
    let camData = JSON.parse(localStorage.getItem(`Magazin_Caisse_LocalD`));
    let Offline = JSON.parse(localStorage.getItem("Magazin_Caisse_Offline"));
    let caisseData = OneGConf.forPID
    const CaisseID = caisseData.CA_Name;
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(10)
    let {genre} = useParams()
    const [tableData, setTableData] = useState([SKLT.STableSlt]); 
    const [selectedItem, setSelectedItem] = useState();
    const [loadingPage, setLoadingPage] = useState(true)
    const [keyBordI, setKeyBoedI] = useState('')
    const [floatOpen, setFloatOpen] = useState(false)
    const [articleNow, setArticleNow] = useState([])
    const [articleList, setArticleList] = useState([])
    const [selectedArticleList, setSelectedArticleList] = useState([])
    const [genreListe, setGenreList] = useState([])

  /*#########################[UseEffect]##################################*/
  useEffect(() => {
    // axios.post(`${GConf.ApiLink}/menu`, {
    //   PID: OneGConf.forPID.PID,
    //   })
    //   .then(function (response) {
    //     setTableData(response.data)
    //     setLoadingPage(false)
    //   }).catch((error) => {
    //     if(error.request) {
    //       toast.error(<><div><h5>Probleme de Connextion</h5> Chargemment du ancien stock  </div></>, GConf.TostInternetGonf)   
    //     setTableData(Offline.stock)
    //     }
    // });
      axios.post(`${GConf.ApiRouterOneLink}/nv/stock`, {
            forPID: OneGConf.forPID.PID,
            factureD: 'factureD',
        })
        .then(function (response) {
            setArticleList(response.data)
            GenerateGenreListe(response.data)
            setSelectedArticleList(response.data.filter(article => article.Genre == response.data[0].Genre))
            setLoadingPage(false)
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> </div></>, GConf.TostInternetGonf)   
            }
        });

  }, [])

  /*#########################[Functions]##################################*/
  const NavigateFunction = (link) => {  navigate(link) }
  const GenerateGenreListe = (listeTarget) =>{
    const distinctNames = [...new Set(listeTarget.map(obj => obj.Genre))];
    setGenreList(distinctNames)
  }

  const SetSelectedListeFunc = (selectedGenre,index) =>{
    const selectedListe =  articleList.filter(article => article.Genre == selectedGenre)
    setSelectedArticleList(selectedListe)
    setActiveIndex(index)
}

  const BtnClicked = (value) =>{
        if (value === 'C') { 
            setKeyBoedI('') 
        } 
        else if (value === '.' ) { 
            //setKeyBoedI(parseFloat(keyBordI)) 
            //alert(parseFloat(keyBordI))
            setFloatOpen(true)
        } 
        else {
            //setKeyBoedI(Number(keyBordI+value)) 
            
            if (floatOpen) {
                setKeyBoedI(prevInput => prevInput + '.' + parseFloat(value))
                setFloatOpen(false)
            } else {
                setKeyBoedI(prevInput => prevInput + parseFloat(value))
            }
        }
  }
  const SaveArticleDansPnnier = (indexId) =>{
    if (articleNow.length == 0) { alert('select ')} 
    else {
          const searchObject = Offline.pannier.find((article) => article.A_Code == articleNow.A_Code);
          if (searchObject) {
              let IndexOfArticle = Offline.pannier.findIndex((article) => article.A_Code == indexId)
              Offline.pannier[IndexOfArticle].Qte = Offline.pannier[IndexOfArticle].Qte + parseInt(keyBordI)
              Offline.pannier[IndexOfArticle].PU = ((Offline.pannier[IndexOfArticle].Qte) * Offline.pannier[IndexOfArticle].Prix ).toFixed(3) 
               
              localStorage.setItem(`Magazin_Caisse_Offline`,  JSON.stringify(Offline));
              toast.info(`${articleNow.Name} Reajouter Avec Quantite : ${keyBordI}`, GConf.TostPannier)
              
              
          } else {
              let arrayToAdd = {id: Offline.pannier.length+1 , A_Code: indexId, Name: articleNow.Name, Prix: articleNow.Prix_vente, Qte: parseInt(keyBordI), PU: articleNow.Prix_vente}
              Offline.pannier.push(arrayToAdd)
            
              localStorage.setItem(`Magazin_Caisse_Offline`,  JSON.stringify(Offline));
              toast.info(`${articleNow.Name}  Ajouter !, La Quantite est : ${keyBordI}`, GConf.TostPannier)
              
            }
    }
    
  }
  const SelectArticle = (indexId) =>{
    setSelectedItem(indexId)
    setArticleNow(tableData[indexId])
  }

  /*############################[Card]#################################*/
  const ArticleCard = (props) =>{
    return(<>
        <div className='col-6'>
            <div className={`card p-2 shadow-sm mb-3 border-div  ${props.index == selectedItem ? 'bg-white text-danger' : OneGConf.themeMode == 'dark' ? 'bg-dark-theme-3 text-white' : ''}`} onClick={() => SelectArticle(props.index)} style={{cursor:'pointer'}} >
                <div className='row'>
                    <div className='col-8 align-self-center'>
                       <div className='mb-0'>{props.data.Name}</div>  
                       <small className='mb-0'>{props.data.A_Code}</small>  
                      </div>
                    <div className='col-2 align-self-center'> {props.data.Prix_vente}</div>
                    <div className='col-2 align-self-center'> {props.data.Quantite}</div>
                </div> 
                
            </div>
        </div>
    </>)
  }
  const AddToPannier = () =>{
      return(<>
              <div className={`card card-body shadow-sm mb-2 sticky-top border-div ${OneGConf.themeMode == 'dark' ? 'bg-dark-theme-3 text-white' : '' } `} style={{top:'70px'}}>
                  <Input type='number'   size='massive'  icon='barcode' value={keyBordI}  autoFocus={true} onChange={ (e) => {setKeyBoedI(Number(e.target.value))}}   iconPosition='left' placeholder=' '  fluid className='mb-1' />
                  <ClavierCard />
                  <Button disabled={false} fluid style={{height:'50px'}} className='border-div bg-system-btn' onClick={() => SaveArticleDansPnnier()}>  <Icon name='check' /> Ajouter</Button>
              </div>
          </>)
  }
  const ClavierCard = () =>{
      const BtnCard = (props) =>{
          return(<>
              <Button className={`shadow-sm ${props.bg == true ? 'bg-danger text-white ' : OneGConf.themeMode == 'dark' ? 'bg-dark-theme-3 text-white border-dark' : 'bg-white' } border mb-1 border-div `} style={{width:'100%', height:'60px', backgroundColor:'red'}} onClick={(e) => BtnClicked(props.value) } ><h2>{props.value}</h2></Button>
          </>)
      }
      return(<>
          <div className='row '>
              <div className='col-4 p-2'><BtnCard value='1' /></div>
              <div className='col-4 p-2'><BtnCard value='2' /></div>
              <div className='col-4 p-2'><BtnCard value='3' /></div>
              <div className='col-4 p-2'><BtnCard value='4' /></div>
              <div className='col-4 p-2'><BtnCard value='5' /></div>
              <div className='col-4 p-2'><BtnCard value='6' /></div>
              <div className='col-4 p-2'><BtnCard value='7' /></div>
              <div className='col-4 p-2'><BtnCard value='8' /></div>
              <div className='col-4 p-2'><BtnCard value='9' /></div>
              <div className='col-4 p-2'><BtnCard value='0' /></div>
              <div className='col-4 p-2'><BtnCard value='.' bg={floatOpen} /></div>
              <div className='col-4 p-2'><BtnCard value='C' /></div>
          </div>
      </>)
  }
  const GenreListeCard = (props) =>{
      return(<>
          {/* <span className={`card card-body mb-1  text-danger text-center btn ${OneGConf.themeMode == 'dark' ? 'bg-dark-theme-3 text-white' : 'bg-ligth-theme-3' }`} onClick={() => SetSelectedListeFunc(props.text)}> <h3> {props.text} </h3></span> */}
          <Menu.Item active={activeIndex == props.index} className='rounded-pill border' onClick={() => SetSelectedListeFunc(props.text,props.index)}>
            <span className='text-danger'>
              <b>
                <span className={`bi bi-${activeIndex == props.index ? 'star-fill': 'star'}`}></span> {props.text}
              </b>
            </span>
          </Menu.Item>

      </>)
  }
  const ArticleItemCard = (props) =>{
    return(<>
        <div className='col-6 col-lg-3 mb-1'>
            <div className={`card p-3 text-secondary h-100 btn ${OneGConf.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : ' ' }`} onClick={() => SelectArticle(props.data.P_Code)}>
                <div className='row'>
                    <div className='col-12 text-end'><b>{props.data.Prix_vente.toFixed(3)}</b></div>
                    <div className='col-12'><b><img src={`https://cdn.abyedh.com/images/system/Resto/${props.data.Photo_Path}`} width='60px' height='60px' /></b></div>
                    <div className='col-12'><h6>{props.data.Name}</h6></div>
                </div>
                
            </div>
        </div>
    </>)
  }

  const ProgressLoadingBar = (props) =>{
    return(<>
          <div className={`progress-bar-loading mt-1 ${props.display == true ? '': 'd-none'}`} style={{marginTop:'1px'}}>
                  <div className="progress-bar-loading-value bg-danger"></div>
              </div>
          </>)
  }
  
  return ( <>
  <div className={`${OneGConf.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`} style={{height: '100vh', overflow: 'scroll'}}>
          <BackCard data={OneGConf.backCard.skfamilleList}/>
          <ProgressLoadingBar  display={loadingPage} />
          <br />
          <div className='container-fluid'>
              <div className='row'>
                    <div className='col-12 col-lg-9'> 
                    <div className='spesific-commp p-2' style={{height: '85vh', overflow: 'scroll', overflowX:'hidden'}}>
                              {
                                loadingPage ? 
                                SKLT.CardList
                                :
                                <div className='row'>
                                    <div className='col-12 col-lg-12 spesific-commp-2'  >
                                        <div  className='mb-3' style={{width:'100%', overflowX: 'auto', overflowY : 'hidden',  whiteSpace:'nowrap'}}>
                                            <Menu secondary >
                                                {
                                                    genreListe.map((data,index) => <GenreListeCard key={index} index={index} text={data} /> )
                                                }
                                            </Menu>
                                        </div>
                                        
                                        

                                    </div>
                                    <div className='col-12 col-lg-12'>
                                        <div className='row'>
                                            {selectedArticleList.map((data,index) => <ArticleItemCard key={index} data={data} />)}
                                        </div>
                                        
                                    </div>
                                </div>
                              }     
                            
                        </div> 
                    </div>
                    <div className='col-12 col-lg-3'> 
                          <NavLink exact='true'  to={`/C/L/nvskp `} className='card card-body mb-2 w-100 text-center rounded-pill shadow-sm d-inline-block ' >
                            <h4 style={{color : GConf.themeColor}}> <span className={`bi bi-basket me-1 `}></span>Pannier </h4>
                          </NavLink> 
                          <AddToPannier />
                    </div>
                    
              </div> 
       </div>
  </div>
  </> );
}

export default StockList;