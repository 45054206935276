import React, { useEffect, useRef, useState } from 'react';
import OneGConf from './../../../InterFaces/RouterOne/Assets/OneGConf';
import BackCard from '../Assets/Cards/backCard'
import { Fade } from 'react-reveal';
import { Button,  Dropdown, Icon, Input, Label, Loader, Tab } from 'semantic-ui-react';
import useGetArticles from '../../../AssetsM/Hooks/fetchArticles';
import useGetClients from '../../../AssetsM/Hooks/fetchClient';
import { toast } from 'react-toastify';
import GConf from '../../../AssetsM/generalConf';
import axios from 'axios';
import FrameForPrint from '../../../AssetsM/Cards/frameForPrint';
import usePrintFunction from '../../../AssetsM/Hooks/printFunction';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Modal } from 'semantic-ui-react';
//import { io } from "socket.io-client";
import { QrReader } from 'react-qr-reader';


const MainDataCard = ({factureD, setFactureD,clientList}) =>{
    return (<>
            <div className='ccard-body mb-2'>
                <h5>Client  </h5>
                <datalist id="clientList">
                            {clientList.map((test) =>
                            <option key={test.PK} value={test.Name}>{test.Code_Fiscale} - {test.Gouv}</option>
                            )}
                </datalist>
                <Input icon='user' list="clientList"  onChange={(e, { value }) => setFactureD({...factureD, client: value })} size="small" iconPosition='left' placeholder='Client'  fluid className='mb-1' />
            </div>
    </>)
}
const SelectClientFideliteCard = ({clientFidele, setClientFidelle ,clientList}) =>{
    const SetClientFidelite = (e) =>{
        if (e.which == 13) {
            setClientFidelle(clientList.find(obj => obj.CL_ID == e.target.value))
        } else {
            
        }
        
    }
    return (<>
            <div className='ccard-body mb-2'>
                {/* <datalist id="clientList">
                            {clientList.map((test) =>
                            <option key={test.PK} value={test.CL_ID}>{test.Phone} - {test.CL_Name}</option>
                            )}
                </datalist> */}
                <Input icon='star' list="clientList" size='large' onKeyPress={(e) => SetClientFidelite(e)}    iconPosition='left' placeholder='Client Fidelite'  fluid className='mb-1' />
                {/* <Input icon='user' list="clientList" size='large'   onChange={(e, { value }) => setClientFidelle(clientList.find(obj => obj.CL_ID == value))}  iconPosition='left' placeholder='Client'  fluid className='mb-1' /> */}
            </div>
    </>)
}
function CaisseSimple() {
    /*#########################[Const]##################################*/
    const Today = new Date()
    let camData = OneGConf.forPID;
    const Cam_ID = 5249829289889;
    const CaisseID = camData.C_ID;
    const inputRef = useRef(null);
    const [factureD, setFactureD] = useState({client:'PASSAGER', Camion : Cam_ID , jour: Today.toISOString().split('T')[0], totale: 0 , articles:[]})
    const [articleNow, setArticleNow] = useState([])
    const [articleList, setArticleList] = useState([])
    const [gettedFID, setFID] = useState('')
    const [keyBordI, setKeyBoedI] = useState('')
    const [peseState, setPeseState] = useState(false)
    const [saveBtnState, setSaveBtnState] = useState(false)
    const [loaderState, setLS] = useState(false)
    const [autofocusState, setAutoFocus] = useState(false)
    const [loadingPage, setLoadingP] = useState(true)
    const [toPrintFID, setToPrintFOD] = useState('5555')
    const [modalS, setModalS] = useState(false)
    const [modalT, setModaT] = useState(false)

    /*2- Paymment */
    const [keyaPaymment, setKeyPaymment] = useState('')
    const [payBtnState, setPayBtnState] = useState(false)

    /*3- Bons */
    const [selectedBon, setSelectedBon] = useState(1)
    const [keyBonsValue, setKeyBonsValue] = useState('')
    const [selectedPoucentage, setSelectedPourcentage] = useState(0)
    const [floatOpen, setFloatOpen] = useState(false)

    /*4- Clients */
    const [clientList, setClientList] = useState([])
    const [clientCredit, setClientCredit ] = useState()
    const [clientFidele, setClientFidelle ] = useState()
    const [fideliteState, setFideliteState ] = useState()
    const [scanResult, setScanResult] = useState(false);

    //const [stopStream, setStopStram] = useState(false)
    let Offline = JSON.parse(localStorage.getItem(`Magazin_Caisse_Offline`));
    const panes = [
        {
            menuItem: { key: 'start', icon: 'add circle', content: 'Entrer ', className:`p-4 ${OneGConf.themeMode == 'dark' ? 'bg-dark-theme-1 text-white' : 'bg-ligth-theme-1' } `}, 
            render: () => <Tab.Pane attached={false}> <AddArticles /> </Tab.Pane>,
        },
        {
            menuItem: { key: 'articles', icon: 'bitcoin', content: 'Paymment ' , className:`p-4 ${OneGConf.themeMode == 'dark' ? 'bg-dark-theme-1 text-white' : 'bg-ligth-theme-1' } ` }, 
            render: () => <Tab.Pane attached={false}> <PaymmentCard />  </Tab.Pane>,
        },
        {
            menuItem: { key: 'bons', icon: 'ticket', content: 'Calcuer Bons ' , className:`p-4 ${OneGConf.themeMode == 'dark' ? 'bg-dark-theme-1 text-white' : 'bg-ligth-theme-1' } ` }, 
            render: () => <Tab.Pane attached={false}> <CalculerBons />  </Tab.Pane>,
        },
        {
            menuItem: { key: 'client', icon: 'user', content: 'Client ' , className:`p-4 ${OneGConf.themeMode == 'dark' ? 'bg-dark-theme-1 text-white' : 'bg-ligth-theme-1' } ` }, 
            render: () => <Tab.Pane attached={false}> <CreditClient />  </Tab.Pane>,
        },
     
    ]
    const [qrData, setQRData] = useState("Not Found");

    /*#########################[Use Effect]##################################*/
    useEffect(() => {
        //setClientList(Offline.client)

        axios.post(`${GConf.ApiRouterOneLink}/nv/stock`, {
            forPID: camData.PID,
          }).then(function (response) {
            setArticleList(response.data)
            setLoadingP(false)
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Chargemment du ancien stock  </div></>, GConf.TostInternetGonf)   
              setArticleList(Offline.stock)
              setLoadingP(false)
            }
        });

        //if stock regler non 
        // axios.post(`${GConf.ApiRouterOneLink}/sk/reglemment/check`, {
        //     forPID: camData.PID,
        //     camId : Cam_ID,
        //     jour : Today.toISOString().split('T')[0]
        //   })
        //   .then(function (response) {
        //     if(response.data.length != 0) {
        //         setSaveBtnState(true)
        //     }
        //   })
    }, [])

    /*#########################[Functions]##################################*/
    const AddArticleToList = ()=>{
        if (!articleNow.A_Code) {toast.error("Code à barre Invalide !", GConf.TostErrorGonf)}
        else if (!articleNow.Name || articleNow.Name == '') {toast.error("Name Invalide !", GConf.TostErrorGonf)}
        else if (!articleNow.Qte || articleNow.Qte == '') {toast.error("Quantite Invalide !", GConf.TostErrorGonf)}
        else{
            const searchObject = factureD.articles.find((article) => article.A_Code == articleNow.A_Code);
            if (searchObject) {
                let IndexOfArticle = factureD.articles.findIndex((article) => article.A_Code == articleNow.A_Code)
                factureD.articles[IndexOfArticle].Qte = factureD.articles[IndexOfArticle].Qte + parseInt(articleNow.Qte)
                factureD.articles[IndexOfArticle].PU = ((factureD.articles[IndexOfArticle].Qte) * factureD.articles[IndexOfArticle].Prix ).toFixed(3) 
                setArticleNow([{}])

                setFactureD({...factureD, totale: MakeSum() })
                setAutoFocus(false)
                
            } else {
                let prix_u = (articleNow.Qte * articleNow.Prix_piece).toFixed(3)
                let arrayToAdd = {id: factureD.articles.length+1 , A_Code: articleNow.A_Code, Name: articleNow.Name, Prix: articleNow.Prix_piece, Qte: parseInt(articleNow.Qte), PU: prix_u}
                factureD.articles.push(arrayToAdd)
                setArticleNow([])

                setFactureD({...factureD, totale: MakeSum() })
                setAutoFocus(false)    
            }
        }        
    }
    const DeleteFromUpdateList = (value) =>{
        const searchObject= factureD.articles.findIndex((article) => article.A_Code == value);
        factureD.articles.splice(searchObject, 1);
        let resteArticles = factureD.articles;
        setFactureD({...factureD, articles: resteArticles })
    }
    const GetArticleData = (value) =>{
        const searchObject= articleList.find((article) => article.A_Code == value);
        let Prix_piece = (searchObject.Prix_vente / searchObject.Groupage)
        searchObject.Prix_piece = Prix_piece.toFixed(3)
        setArticleNow(searchObject);
        setAutoFocus(true)
        
    }
    const MakeSum = () => {
        let tot = 0
        factureD.articles.map( (art) => { 
            tot = tot +  parseFloat(art.PU)
        })
        return (tot.toFixed(3))
    }
    const SaveFacture = () =>{
            if (!factureD.client) {toast.error("Client est Invalide !", GConf.TostErrorGonf)}
            else if (!factureD.jour ) {toast.error("Date est Invalide !", GConf.TostErrorGonf)}
            else if (!factureD.totale) {toast.error("totale est Invalide !", GConf.TostErrorGonf)}
            else if (!factureD.articles || factureD.articles.length == 0) {toast.error("article list est Invalide !", GConf.TostErrorGonf)}
            else {
                setLS(true)
                axios.post(`${GConf.ApiRouterOneLink}/nv/ajouter`, {
                    forPID: camData.PID,
                    factureD: factureD,
                })
                .then(function (response) {

                    if(response.status = 200) {
                        setSaveBtnState(true)
                        setFID(response.data.FID)
                        toast.success("Facture Enregistreé !", GConf.TostSuucessGonf)
                        setLS(false)
                        setFID(response.data.FID)
                    }
                    else{
                        toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                        setLS(false)
                    }
                }).catch((error) => {
                    if(error.request) {
                      toast.error(<><div><h5>Probleme de Connextion</h5> La facture sera enregisstrer sur votre appareil  </div></>, GConf.TostInternetGonf)   
                      Offline.factureToSave.push(factureD)
                      localStorage.setItem(`Magazin_Caisse_Offline`,  JSON.stringify(Offline));
                      setLS(false)
                    }
                });

            }       
    }
    const BtnClicked = (value) =>{
        if (value === 'C') { 
            setKeyBoedI('') 
        } 
        else if (value === '.' ) { console.log('.') } 
        else {
            setKeyBoedI(Number(keyBordI+value)) 
        }
    }  
    const  EnterKyPressed = (e) =>{
        if (e.which == 13) {
           // alert(keyBordI)
           GetArticleDataByAID()
        }  
        else{
            //console.log(e.which)
        } 
            
    }
    const GetArticleDataByAID = () =>{
        console.log(articleList)
        const searchObjectFirst= articleList.find((article) => article.A_Code == keyBordI);

        if (searchObjectFirst) {
            //alert('exist yess')
            //GetArticleData(keyBordI)
            //AddArticleToList()
            const searchObject = factureD.articles.find((article) => article.A_Code == keyBordI);
            if (searchObject) {
                let IndexOfArticle = factureD.articles.findIndex((article) => article.A_Code == keyBordI)
                factureD.articles[IndexOfArticle].Qte = factureD.articles[IndexOfArticle].Qte + 1
                factureD.articles[IndexOfArticle].PU = ((factureD.articles[IndexOfArticle].Qte) * factureD.articles[IndexOfArticle].Prix ).toFixed(3) 
                setArticleNow([{}])

                setFactureD({...factureD, totale: MakeSum() })
                setAutoFocus(false)
                setKeyBoedI('')
                
            } else {
                let arrayToAdd = {id: factureD.articles.length+1 , A_Code: keyBordI, Name: searchObjectFirst.Name, Prix: searchObjectFirst.Prix_vente, Qte: 1, PU: searchObjectFirst.Prix_vente}
                factureD.articles.push(arrayToAdd)
                setArticleNow([])

                setFactureD({...factureD, totale: MakeSum() })
                setAutoFocus(false)   
                setKeyBoedI('') 
            }

        } else {
            toast.error(<>
                        <div className='card-body w-100 text-center'>
                            <span className='bi bi-box-seam bi-lg'></span> 
                            <h3 className='text-white'>Pas D'article</h3> 
                        </div>
                        </>, GConf.TostCaisseGonf)
        }
    }
    const FireKeyPodBtn = () => {
       
    }
    const AddMoreQte = (valueCode) =>{
        let IndexOfArticle = factureD.articles.findIndex((article) => article.A_Code == valueCode)
        factureD.articles[IndexOfArticle].Qte =  keyBordI
        factureD.articles[IndexOfArticle].PU = ((factureD.articles[IndexOfArticle].Qte) * factureD.articles[IndexOfArticle].Prix ).toFixed(3) 
        setArticleNow([{}])

        setFactureD({...factureD, totale: MakeSum() })
        setAutoFocus(false)
        setKeyBoedI('')
    }
    const NewTicket = () =>{
        // setLoadingP(true)
        // setTimeout(() => {
        //     setLoadingP(false);
        // }, 500);
        // setFactureD({client:'PASSAGER', Caisse : CaisseID , jour: Today.toISOString().split('T')[0], totale: 0 , articles:[]})
        // setArticleNow([])
        // setAutoFocus(true)
        // setKeyBoedI('')
        // setFloatOpen(false)
        // setPeseState(false)
        // setRechercheKey('')
        // setRechercheListe([])
        // setModalS(false)
        // setModaT(false)
        // setFID('')
        // setSaveBtnState(false)
        // setLS(false)
        // setKeyPaymment(0)
        // setSelectedBon(1)
        window.location.reload()
        
    }
    /*2- Paymment */
    const PaymmentBtnClicked = (value) =>{
        if (value === 'C') { 
            setKeyPaymment('') 
        } 
        else if (value === '.' ) { 
            setFloatOpen(true)
        } 
        else {             
            if (floatOpen) {
                setKeyPaymment(prevInput => prevInput + '.' + parseFloat(value))
                setFloatOpen(false)
            } else {
                setKeyPaymment(prevInput => prevInput + parseFloat(value))
            }
        }
    }
    const SavePaymmentCache = () =>{

        if (!gettedFID || gettedFID == '') {toast.error("Une Erreur s'est Produit !", GConf.TostErrorGonf)}
        else if (keyaPaymment == '') {toast.error("Solde Insufisante !", GConf.TostErrorGonf)}
        else if (JSON.parse(factureD.totale) > JSON.parse(keyaPaymment)) {toast.error("Solde Insufisante !", GConf.TostErrorGonf)}
        else {
            setLoadingP(true)
            axios.post(`${GConf.ApiRouterOneLink}/nv/payee/espece`, {
                forPID: CaisseID,
                espece: keyaPaymment,
                fid : gettedFID
            })
            .then(function (response) {
                if(response.status = 200) {
                    setPayBtnState(true)
                    toast.success("Facture Payeé !", GConf.TostSuucessGonf)
                    setLoadingP(false)
                    setToPrintFOD(gettedFID)
                }
                else{
                    toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                    setLoadingP(false)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>Probleme de Connextion</h5> Paymment à été enregistre localmment   </div></>, GConf.TostInternetGonf)   
                   Offline.factureToSave[gettedFID].Espece = keyaPaymment
                   Offline.factureToSave[gettedFID].State = 'Payee'
                   localStorage.setItem(`${OneGConf.routerTagName}_Offline`,  JSON.stringify(Offline));
                  setLoadingP(false)
                }
            });

        }
    }
    /* 3- Bons */
    const BonsBtnClicked = (value) =>{
        if (value === 'C') { 
            setKeyBonsValue('') 
        } 
        else if (value === '.' ) { 
            setFloatOpen(true)
        } 
        else {             
            if (floatOpen) {
                setKeyBonsValue(prevInput => prevInput + '.' + parseFloat(value))
                setFloatOpen(false)
            } else {
                setKeyBonsValue(prevInput => prevInput + parseFloat(value))
            }
        }
    }
    const BonSlected= (value) =>{
        if (keyBonsValue != '' && parseFloat(keyBonsValue) != 0) {
            setSelectedPourcentage(value)
            let pureValue = keyBonsValue * ( 1 - (value / 100)) 
            setSelectedBon(pureValue)
        } else {
            //setSelectedBon(0)
        }
       
    }
    const CalculateBons = (genre) => {
        if (genre == 'Plus') {
            return {qte : Math.trunc(factureD.totale / selectedBon) +1 , bon : keyBonsValue, valeur : selectedBon, pourcentage: selectedPoucentage}
        } else {
            return {qte : Math.trunc(factureD.totale / selectedBon) , bon : keyBonsValue , valeur : selectedBon, pourcentage: selectedPoucentage}
        }
    }
    const SavePaymmentBons = (genre) =>{

        if (!gettedFID || gettedFID == '') {toast.error("Une Erreur s'est Produit !", GConf.TostErrorGonf)}
        else if (selectedBon == '') {toast.error("Solde Insufisante !", GConf.TostErrorGonf)}
        else {
            setLoadingP(true)
            axios.post(`${GConf.ApiRouterOneLink}/nv/payee/bons`, {
                forPID: CaisseID,
                bons: CalculateBons(genre),
                fid : gettedFID
            })
            .then(function (response) {
                if(response.status = 200) {
                    setPayBtnState(true)
                    toast.success("Facture Payeé !", GConf.TostSuucessGonf)
                    setLoadingP(false)
                    setToPrintFOD(gettedFID)
                }
                else{
                    toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                    setLoadingP(false)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>Probleme de Connextion</h5> Paymment à été enregistre localmment  </div></>, GConf.TostInternetGonf)   
                  Offline.factureToSave[gettedFID].Paye_Bons = JSON.stringify(CalculateBons(genre))
                  Offline.factureToSave[gettedFID].State = 'Payee'
                  localStorage.setItem(`${OneGConf.routerTagName}_Offline`,  JSON.stringify(Offline));
                  setLoadingP(false)
                }
            });

        }
    }

    /*4- Clients */
    const SaveClientCredit = (genre) =>{

        if (!gettedFID || gettedFID == '') {toast.error("Une Erreur s'est Produit !", GConf.TostErrorGonf)}
        else if (clientCredit == '') {toast.error("Solde Insufisante !", GConf.TostErrorGonf)}
        else {
            setLoadingP(true)
            axios.post(`${GConf.ApiRouterOneLink}/nv/client/credit`, {
                forPID: CaisseID,
                clientId: clientCredit.CL_ID,
                fid : gettedFID
            })
            .then(function (response) {
                if(response.status = 200) {
                    setPayBtnState(true)
                    toast.success("Crédit Enregistrer !", GConf.TostSuucessGonf)
                    setLoadingP(false)
                    setToPrintFOD(gettedFID)
                }
                else{
                    toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                    setLoadingP(false)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>Probleme de Connextion</h5> La facture sera enregisstrer sur votre appareil  </div></>, GConf.TostInternetGonf)   
                  Offline.factureToSave[gettedFID].Client = clientCredit.CL_ID
                  Offline.factureToSave[gettedFID].State = 'Credit'
                  localStorage.setItem(`${OneGConf.routerTagName}_Offline`,  JSON.stringify(Offline));
                  setLoadingP(false)
                }
            });

        }
    }
    const SaveClientFidelle = () =>{

        if (!gettedFID || gettedFID == '') {toast.error("Une Erreur s'est Produit !", GConf.TostErrorGonf)}
        else if (clientFidele == '') {toast.error("Solde Insufisante !", GConf.TostErrorGonf)}
        else {
            setLoadingP(true)
            axios.post(`${GConf.ApiRouterOneLink}/nv/client/fidelite`, {
                forPID: CaisseID,
                clientId: clientFidele.CL_ID,
                fid : gettedFID
            })
            .then(function (response) {
                if(response.status = 200) {
                    setFideliteState(true)
                    toast.success("Crédit Enregistrer !", GConf.TostSuucessGonf)
                    setLoadingP(false)

                }
                else{
                    toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                    setLoadingP(false)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>Probleme de Connextion</h5> La facture sera enregisstrer sur votre appareil  </div></>, GConf.TostInternetGonf)   
                  Offline.factureToSave[gettedFID].Client = clientCredit.CL_ID
                  Offline.factureToSave[gettedFID].State = 'Payee'
                  localStorage.setItem(`${OneGConf.routerTagName}_Offline`,  JSON.stringify(Offline));
                  setLoadingP(false)
                }
            });

        }
    }

    const PrintFunction = (frameId) =>{ usePrintFunction(frameId)}
    /*#########################[Component]##################################*/ 
    /*1- Ajouter Article */
    const AddArticles = ({}) =>{
        return (<>
                <div className='row'>
                    <div className='col-12 col-md-5'>
                        <div className='mb-2'>
                            <div className='row'>
                                    <div className='col-8'><Button disabled={saveBtnState && !payBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={()=> NewTicket()}>  <Icon name='redo' /> N. Ticket</Button></div> 
                                    <div className='col-4'><Button  fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={() =>PrintFunction('openCaisse')}>  <Icon name='lock open' /> </Button></div> 
                            </div> 
                            
                            <h2><span className='bi bi-keyboard'></span>  : {keyBordI}</h2>
                            {/* <Input type='number' inputRef={inputRef}  size='massive'  icon='barcode'   onKeyPress={(e) => EnterKyPressed(e)}  autoFocus={true}   iconPosition='left' placeholder=' '  fluid className='mb-1' /> */}
                            <input type='text' className='form-control form-control-lg'  ref={inputRef} onKeyPress={(e) => EnterKyPressed(e)} autoFocus={true} />
                            <br />
                            <div className='row '>
                                <div className='col-6'>
                                    <Button disabled={saveBtnState} fluid style={{height:'50px'}} className='border-div bg-system-btn' onClick={() => GetArticleDataByAID(keyBordI)}>  <Icon name='check' /> Ajouter</Button>
                                </div>
                                <div className='col-6'>
                                    <Button disabled={saveBtnState} fluid style={{height:'50px'}} className={`border-div ${peseState ? 'bg-warning text-white' : 'bg-white border '}`} onClick={() => setPeseState(!peseState)}>  <Icon name='balance' /> Pesé </Button>
                                </div>
                            </div>
                            <h5>Enregistrer </h5> 
                            
                            <Button disabled={saveBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn' onClick={() => SaveFacture()}>  <Icon name='save' /> Valideé Pour PASSAGER <Loader inverted active={loaderState} inline size='tiny' className='ms-2 text-danger'/></Button>
                        </div>
                    </div>
                    <div className='col-12 col-md-7 align-self-center'>
                       <div className='row'>
                            <div className='col-6'><Button disabled={saveBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={() => setModalS(true)}>  <Icon name='search' /> Recherche </Button></div>
                            <div className='col-6'><Button disabled={saveBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={() => setModaT(true)}>  <Icon name='search' /> Trouver  </Button></div>
                        </div> 
                        <ClavierCard /> 
                    </div>
                </div> 
                
        </>)
    }
    const ArticleListCard = (props) =>{
        return(<>
                    <div className={`card shadow-sm p-2 mb-1 me-1 rounded-pill  ${GConf.themeMode == 'dark' ? 'bg-dark-theme-4 text-white' : 'bg-ligth-theme-4' } `} >
                        <div className='row p-0'>
                            <div className='col-1 align-self-center text-start'><Button disabled={saveBtnState} onClick={() => DeleteFromUpdateList(props.dataA.A_Code)} icon="times" className='rounded-circle p-2 text-white bg-danger'></Button></div>
                            <div className='col-9 text-start align-self-center ps-4'>
                                <div>{props.dataA.Name}</div>
                                <b className='text-blod'>{props.dataA.Qte}</b> x {props.dataA.Prix.toFixed(3)} = <span className='text-warning'>{parseFloat(props.dataA.PU).toFixed(3)}</span>
                            </div>
                            <div className='col-2 align-self-center m-0'><Button disabled={saveBtnState} onClick={() => AddMoreQte(props.dataA.A_Code,keyBordI)} icon="plus cart" className='rounded-circle p-2 text-white bg-info'></Button></div>
                        </div>
                    </div>
                </>)
    }
    const TotaleCard = () =>{
        return(<>
                <div className={`card card-body shadow-sm mb-2 sticky-top border-div ${GConf.themeMode == 'dark' ? 'bg-dark-theme-3 text-white' : 'bg-ligth-theme-3' } `} style={{top:'70px'}}>
                    <div className='row'>
                        <div className='col-9 align-self-center text-start'><h1>Totale : {factureD.totale}</h1></div>
                        <div className='col-3 align-self-center text-center'><h5 className='mb-0'>{factureD.articles.length}</h5> articles</div>
                    </div>
                    
                   
                </div>
            </>)
    }
    const ClavierCard = () =>{
        const BtnCard = (props) =>{
            return(<>
                <Button className={`shadow-sm ${props.bg == true ? 'bg-danger text-white ' : GConf.themeMode == 'dark' ? 'bg-dark-theme-3 text-white border-dark' : 'bg-white' } border mb-1 border-div `} style={{width:'100%', height:'60px', backgroundColor:'red'}} onClick={(e) => BtnClicked(props.value) } ><h2>{props.value}</h2></Button>
            </>)
        }
        return(<>
            <div className='row '>
                <div className='col-4 p-2'><BtnCard value='1' /></div>
                <div className='col-4 p-2'><BtnCard value='2' /></div>
                <div className='col-4 p-2'><BtnCard value='3' /></div>
                <div className='col-4 p-2'><BtnCard value='4' /></div>
                <div className='col-4 p-2'><BtnCard value='5' /></div>
                <div className='col-4 p-2'><BtnCard value='6' /></div>
                <div className='col-4 p-2'><BtnCard value='7' /></div>
                <div className='col-4 p-2'><BtnCard value='8' /></div>
                <div className='col-4 p-2'><BtnCard value='9' /></div>
                <div className='col-4 p-2'><BtnCard value='0' /></div>
                <div className='col-4 p-2'><BtnCard value='.' bg={floatOpen} /></div>
                <div className='col-4 p-2'><BtnCard value='C' /></div>
            </div>
        </>)
    }
    const ProgressLoadingBar = (props) =>{
        return(<>
            <div className={`progress-bar-loading mt-1 ${props.display == true ? '': 'd-none'}`} style={{marginTop:'1px'}}>
                    <div className="progress-bar-loading-value bg-danger"></div>
                </div>
            </>)
    }
    const AddArticlesOL = ({}) =>{
        return (<>
                <div className='row'>
                    <div className='col-12 col-md-5'>
                        <div className='mb-2'>
                            <h5>Entreé</h5> 
                            <Input type='number' size='massive'  icon='braille' value={keyBordI} onKeyPress={(e) => EnterKyPressed(e)} autoFocus={true} onChange={ (e) => {setKeyBoedI(Number(e.target.value)) ; console.log(keyBordI) }}   iconPosition='left' placeholder=' '  fluid className='mb-1' />
                            <br />
                            <div className='row'>
                                <div className='col-6'>
                                    <Button disabled={saveBtnState} fluid style={{height:'50px'}} className='border-div bg-system-btn' onClick={() => GetArticleDataByAID()}>  <Icon name='check' /> Ajouter</Button>
                                </div>
                                <div className='col-6'>
                                    <Button disabled={saveBtnState} fluid style={{height:'50px'}} className='border-div bg-system-btn' onClick={() => FireKeyPodBtn()}>  <Icon name='balance' /> Pesé</Button>
                                </div>
                            </div>
                            <h5>Controle</h5> 
                            <Button disabled={saveBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={AddArticleToList}>  <Icon name='redo' /> Nouveaux Ticket</Button>
                            <Button disabled={saveBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn' onClick={AddArticleToList}>  <Icon name='save' /> Valideé Pour PASSAGER</Button>
                        </div>
                    </div>
                    <div className='col-12 col-md-7 align-self-center'>
                        <ClavierCard /> 
                    </div>
                </div> 
                
        </>)
    }
    const ArticleListCardold = (props) =>{
        return(<>
                <Fade>
                    <div className='card shadow-sm p-2 mb-1 rounded-pill ps-4'>
                        <div className='row p-0'>
                            <div className='col-1 align-self-center'><Button disabled={saveBtnState} onClick={() => DeleteFromUpdateList(props.dataA.A_Code)} icon="times" className='rounded-circle p-2 text-white bg-danger'></Button></div>
                            <div className='col-9 text-start align-self-center ps-3'>
                                <div>{props.dataA.Name}</div>
                                <b className='text-danger'>{props.dataA.Qte}</b> * {props.dataA.Prix} = <span className='text-warning'>{props.dataA.PU}</span>
                            </div>
                            <div className='col-2 align-self-center m-0'><Button disabled={saveBtnState} onClick={() => AddMoreQte(props.dataA.A_Code)} icon="plus cart" className='rounded p-2 text-white bg-info'></Button></div>
                        </div>
                    </div>
                </Fade>
                </>)
    }
    const TotaleCardOLD = () =>{
        return(<>
                <div className='card card-body shadow-sm mb-2 sticky-top border-div' style={{top:'70px'}}>
                    <div className='row'>
                        <div className='col-9 align-self-center text-start'><h1>Totale : {factureD.totale}</h1></div>
                        <div className='col-3 align-self-center text-center'><h5 className='mb-0'>{factureD.articles.length}</h5> articles</div>
                    </div>
                    
                   
                </div>
            </>)
    }
 
    // const BarreCodeReader = () =>{
    //     return(<>
    //     <BarcodeScannerComponent
    //         width={'100%'}
    //         height={250}
    //         stopStream = {stopStream}
    //         onUpdate={(err, result) => {
    //         if (result) 
    //             {setQRData(result.text); 
    //             setStopStram(true);
    //         } 
    //         //else {console.log("Not Found");}
    //         }}
    //     />
    //     </>)

    // }
    const ClavierCardOLD = () =>{
        const BtnCard = (props) =>{
            return(<>
                <Button className='shadow-sm bg-white border mb-1 border-div' style={{width:'100%', height:'60px'}} onClick={(e) => BtnClicked(props.value) } ><h2>{props.value}</h2></Button>
            </>)
        }
        return(<>
            <div className='row '>
                <div className='col-4 p-2'><BtnCard value='1' /></div>
                <div className='col-4 p-2'><BtnCard value='2' /></div>
                <div className='col-4 p-2'><BtnCard value='3' /></div>
                <div className='col-4 p-2'><BtnCard value='4' /></div>
                <div className='col-4 p-2'><BtnCard value='5' /></div>
                <div className='col-4 p-2'><BtnCard value='6' /></div>
                <div className='col-4 p-2'><BtnCard value='7' /></div>
                <div className='col-4 p-2'><BtnCard value='8' /></div>
                <div className='col-4 p-2'><BtnCard value='9' /></div>
                <div className='col-4 p-2'><BtnCard value='0' /></div>
                <div className='col-4 p-2'><BtnCard value='.' /></div>
                <div className='col-4 p-2'><BtnCard value='C' /></div>
            </div>
        </>)
    }

    /*2- Paymment */
    const ClavierPaymmentCard = () =>{
        const BtnCard = (props) =>{
            return(<>
                <Button className={`shadow-sm ${props.bg == true ? 'bg-danger text-white ' : OneGConf.themeMode == 'dark' ? 'bg-dark-theme-3 text-white border-dark' : 'bg-white' } border mb-1 border-div `} style={{width:'100%', height:'60px'}} onClick={(e) => PaymmentBtnClicked(props.value) } ><h2>{props.value}</h2></Button>
            </>)
        }
        return(<>
            <div className='row '>
                <div className='col-4 p-2'><BtnCard value='1' /></div>
                <div className='col-4 p-2'><BtnCard value='2' /></div>
                <div className='col-4 p-2'><BtnCard value='3' /></div>
                <div className='col-4 p-2'><BtnCard value='4' /></div>
                <div className='col-4 p-2'><BtnCard value='5' /></div>
                <div className='col-4 p-2'><BtnCard value='6' /></div>
                <div className='col-4 p-2'><BtnCard value='7' /></div>
                <div className='col-4 p-2'><BtnCard value='8' /></div>
                <div className='col-4 p-2'><BtnCard value='9' /></div>
                <div className='col-4 p-2'><BtnCard value='0' /></div>
                <div className='col-4 p-2'><BtnCard value='.'  bg={floatOpen} /></div>
                <div className='col-4 p-2'><BtnCard value='00' /></div>
                <div className='col-4 p-2'><BtnCard value='10' /></div>
                <div className='col-4 p-2'><BtnCard value='20' /></div>
                <div className='col-4 p-2'><BtnCard value='50' /></div>
            </div>
        </>)
    }
    const PaymmentCard = ({}) =>{
        return (<>
                <div className='row'>
                    <div className='col-12 col-md-7 align-self-center'>
                        <ClavierPaymmentCard /> 
                    </div>
                    <div className='col-12 col-md-5'>
                        <div className='mb-2 '>
                            {/* <Button disabled={saveBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={() => setModaPS(true)}>  <Icon name='search' /> Sahcet & Carte de Recharge </Button> */}
  
                            <h3 className='mb-2'>Totale : {factureD.totale}</h3> 
                            <h3 className='mb-2 mt-1'>Reste : {(keyaPaymment - factureD.totale).toFixed(3)}</h3> 
                                <div className='row'>
                                    <div className='col-9'><Input type='number' size='huge'  icon='money' value={keyaPaymment} onKeyPress={(e) => EnterKyPressed(e)} autoFocus={true} onChange={ (e) => {setKeyPaymment(Number(e.target.value)) }}   iconPosition='left' placeholder=' '  fluid className='mb-1' /></div>
                                    <div className='col-3 ms-0 me-0'> <Button disabled={false} fluid className='border-div   text-white' style={{height:'50px', backgroundColor : OneGConf.themeMode == 'dark' ? '#96999e': '#343536'}} onClick={() => setKeyPaymment('')}> <h2>C</h2> </Button> </div>
                                    
                                </div>
                                
                            <br />
                            <div className='row'>
                                <div className='col-12'>
                                    <Button disabled={payBtnState || !saveBtnState} fluid style={{height:'50px'}} className='border-div bg-system-btn' onClick={() => SavePaymmentCache()}>  <Icon name='check' /> Payeé</Button>
                                </div>
                            </div>
                            <h5>Imprimer</h5> 
                            <div className='row'>
                                <div className='col-9'><Button disabled={!saveBtnState || !payBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={() =>PrintFunction('printFacture')}>  <Icon name='print' /> Imprimer </Button></div>
                                <div className='col-3 text-center'><Button  fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={() =>PrintFunction('openCaisse')}>  <Icon name='lock open' /> </Button></div> 
                            </div>
                            
                        </div>
                    </div>
                </div> 
                
        </>)
    }


    const ListeDesBonsCard = () =>{
        const BtnCard = (props) =>{
            return(<>
                <Button className='shadow-sm bg-white border mb-1 border-div' style={{width:'100%', height:'60px'}} onClick={(e) => BtnClicked(props.value) } ><h2>{props.value}</h2></Button>
            </>)
        }
        return(<>
            <div className='row '>
                <div className='col-6 p-2'><BtnCard value='2000' /></div>
                <div className='col-6 p-2'><BtnCard value='3000' /></div>
                <div className='col-6 p-2'><BtnCard value='4000' /></div>
                <div className='col-6 p-2'><BtnCard value='5000' /></div>
                <div className='col-6 p-2'><BtnCard value='6000' /></div>
                <div className='col-6 p-2'><BtnCard value='7000' /></div>
                <div className='col-6 p-2'><BtnCard value='8000' /></div>
                <div className='col-6 p-2'><BtnCard value='9000' /></div>
            </div>
            </>)
    }
    const PaymmentCardOLD = ({}) =>{
        return (<>
                <div className='row'>
                    <div className='col-12 col-md-7 align-self-center'>
                        <ClavierCard /> 
                    </div>
                    <div className='col-12 col-md-5'>
                        <div className='mb-2'>
                            <h5 className='text-secondary'>Paymment</h5> 
                            <h3 className='mb-2'>Totale : {factureD.totale}</h3> 
                            <h3 className='mb-2 mt-1'>Reste : {factureD.totale}</h3> 
                            <Input type='number' size='large'  icon='braille' value={keyBordI} onKeyPress={(e) => EnterKyPressed(e)} autoFocus={true} onChange={ (e) => {setKeyBoedI(Number(e.target.value)) ; console.log(keyBordI) }}   iconPosition='left' placeholder=' '  fluid className='mb-1' />
                            <br />
                            <div className='row'>
                                <div className='col-12'>
                                    <Button disabled={saveBtnState} fluid style={{height:'50px'}} className='border-div bg-system-btn' onClick={() => GetArticleDataByAID()}>  <Icon name='check' /> Payeé</Button>
                                </div>
                            </div>
                            <h5>Imprimer</h5> 
                            <Button disabled={saveBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={AddArticleToList}>  <Icon name='print' /> Imprimer </Button>
                        </div>
                    </div>
                </div> 
                
        </>)
    }
    const CalculerBonsOLD = () =>{
        return(<>
        
                <div className='row'>
                    <div className='col-12 col-md-4 align-self-center'>
                        <ListeDesBonsCard /> 
                    </div>
                    <div className='col-12 col-md-8'>
                        <div className='row'>
                            <div className='col-6'>
                                    <div className='mb-2'>
                                        <h5 className='text-secondary'>Paymment</h5> 
                                        <h3 className='mb-2'>Totale : {factureD.totale}</h3> 
                                        <h3 className='mb-2 mt-1'>Reste : {factureD.totale}</h3> 
                                        <Input type='number' size='large'  icon='braille' value={keyBordI} onKeyPress={(e) => EnterKyPressed(e)} autoFocus={true} onChange={ (e) => {setKeyBoedI(Number(e.target.value)) ; console.log(keyBordI) }}   iconPosition='left' placeholder=' '  fluid className='mb-1' />
                                        <br />
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Button disabled={saveBtnState} fluid style={{height:'50px'}} className='border-div bg-system-btn' onClick={() => GetArticleDataByAID()}>  <Icon name='check' /> Payeé</Button>
                                            </div>
                                        </div>
                                        <h5>Imprimer</h5> 
                                        <Button disabled={saveBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={AddArticleToList}>  <Icon name='print' /> Imprimer </Button>
                                    </div>
                            </div>
                            <div className='col-6'>
                                    <div className='mb-2'>
                                        <h5 className='text-secondary'>Paymment</h5> 
                                        <h3 className='mb-2'>Totale : {factureD.totale}</h3> 
                                        <h3 className='mb-2 mt-1'>Reste : {factureD.totale}</h3> 
                                        <Input type='number' size='large'  icon='braille' value={keyBordI} onKeyPress={(e) => EnterKyPressed(e)} autoFocus={true} onChange={ (e) => {setKeyBoedI(Number(e.target.value)) ; console.log(keyBordI) }}   iconPosition='left' placeholder=' '  fluid className='mb-1' />
                                        <br />
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Button disabled={saveBtnState} fluid style={{height:'50px'}} className='border-div bg-system-btn' onClick={() => GetArticleDataByAID()}>  <Icon name='check' /> Payeé</Button>
                                            </div>
                                        </div>
                                        <h5>Imprimer</h5> 
                                        <Button disabled={saveBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={AddArticleToList}>  <Icon name='print' /> Imprimer </Button>
                                    </div>
                            </div>
                        </div>  
                    </div>
                </div> 
        </>)
    }
    const ClavierBonsCard = () =>{
        const BtnCard = (props) =>{
            return(<>
                <Button className={`shadow-sm ${props.bg == true ? 'bg-danger text-white ' : OneGConf.themeMode == 'dark' ? 'bg-dark-theme-3 text-white border-dark' : 'bg-white' } border mb-1 border-div `} style={{width:'100%', height:'60px'}} onClick={(e) => BonsBtnClicked(props.value) } ><h2>{props.value}</h2></Button>
            </>)
        }
        return(<>
            <div className='row'>
                <div className='col'><Button fluid className={`shadow-sm  border mb-1 border-div ${selectedPoucentage == 5 ? 'bg-warning border-0 text-danger' : ''}`} style={{  height:'60px'}} onClick={(e) => BonSlected(0) } ><h5><span className='bi bi-gift-fill'></span></h5></Button></div>
                {/* <div className='col'><Button fluid className={`shadow-sm  border mb-1 border-div ${selectedPoucentage == 5 ? 'bg-warning border-0 text-danger' : ''}`} style={{  height:'60px'}} onClick={(e) => BonSlected(5) } ><h5>5%</h5></Button></div> */}
                <div className='col'><Button fluid className={`shadow-sm  border mb-1 border-div ${selectedPoucentage == 8 ? 'bg-warning border-0 text-danger' : ''} `} style={{  height:'60px'}} onClick={(e) => BonSlected(8) } ><h5>8%</h5></Button></div>
                <div className='col'><Button fluid className={`shadow-sm  border mb-1 border-div ${selectedPoucentage == 10 ? 'bg-warning border-0 text-danger' : ''} `} style={{  height:'60px'}} onClick={(e) => BonSlected(10) } ><h5>10%</h5></Button></div>
                <div className='col'><Button fluid className={`shadow-sm  border mb-1 border-div ${selectedPoucentage == 12 ? 'bg-warning border-0 text-danger' : ''} `} style={{  height:'60px'}} onClick={(e) => BonSlected(12) } ><h5>12%</h5></Button></div>
                <div className='col'><Button fluid className={`shadow-sm  border mb-1 border-div ${selectedPoucentage == 15 ? 'bg-warning border-0 text-danger' : ''} `} style={{  height:'60px'}} onClick={(e) => BonSlected(15) } ><h5>15%</h5></Button></div>
            </div>
            <div className='row '>
                <div className='col-4 p-2'><BtnCard value='1' /></div>
                <div className='col-4 p-2'><BtnCard value='2' /></div>
                <div className='col-4 p-2'><BtnCard value='3' /></div>
                <div className='col-4 p-2'><BtnCard value='4' /></div>
                <div className='col-4 p-2'><BtnCard value='5' /></div>
                <div className='col-4 p-2'><BtnCard value='6' /></div>
                <div className='col-4 p-2'><BtnCard value='7' /></div>
                <div className='col-4 p-2'><BtnCard value='8' /></div>
                <div className='col-4 p-2'><BtnCard value='9' /></div>
                <div className='col-4 p-2'><BtnCard value='0' /></div>
                <div className='col-4 p-2'><BtnCard value='.'  bg={floatOpen} /></div>
                <div className='col-4 p-2'><BtnCard value='00' /></div>
                <div className='col-4 p-2'><BtnCard value='10' /></div>
                <div className='col-4 p-2'><BtnCard value='20' /></div>
                <div className='col-4 p-2'><BtnCard value='50' /></div>
            </div>
        </>)
    }
    const CalculerBons = () =>{
        return(<>
        
                <div className='row'>
                    <div className='col-12 col-md-7 align-self-center'>
                        <ClavierBonsCard /> 
                    </div>
                    <div className='col-12 col-md-5'>
                        <div className='row mb-3'>
                            <div className='col-9 align-self-center'><h2><span className='bi bi-keyboard'></span>  : {keyBonsValue == '' ? '0' : parseFloat(keyBonsValue).toFixed(3)} <span className='bi bi-arrow-left-right'></span> {selectedBon == 1 ? '0' : selectedBon.toFixed(3)} </h2>  </div>
                            <div className='col-3 ms-0 me-0'> <Button disabled={false} fluid className='border-div   text-white' style={{height:'50px', backgroundColor : OneGConf.themeMode == 'dark' ? '#96999e': '#343536'}} onClick={() => {setKeyBonsValue(''); setSelectedBon(1) ; setSelectedPourcentage(0)}}> <h2>C</h2> </Button> </div>   
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                    <div className='mb-2'>
                                        <h5 className='text-success'>Avec Reste</h5> 
                                        <h3 className='mb-2'>Nombre : {Math.trunc(factureD.totale / selectedBon) +1 }</h3> 
                                        <h3 className='mb-2 mt-1'>Tot: {((Math.trunc(factureD.totale / selectedBon) +1) * selectedBon).toFixed(3)}</h3> 
                                        <h3 className='mb-2 mt-1'>R : {(((Math.trunc(factureD.totale / selectedBon) +1) * selectedBon).toFixed(3) - factureD.totale).toFixed(3)}</h3> 
                                        <br />
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Button disabled={payBtnState ||!saveBtnState} fluid style={{height:'50px'}} className='border-div bg-success text-white' onClick={() => SavePaymmentBons('Plus')}>  <Icon name='check' /> Payeé</Button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-6'>
                                    <div className='mb-2'>
                                        <h5 className='text-danger'>Sans Reste</h5> 
                                        <h3 className='mb-2'>Nombre : {Math.trunc(factureD.totale / selectedBon)}</h3> 
                                        <h3 className='mb-2 mt-1'>Tot: {((Math.trunc(factureD.totale / selectedBon)) * selectedBon).toFixed(3)}</h3> 
                                        <h3 className='mb-2 mt-1'>T : {(((Math.trunc(factureD.totale / selectedBon)) * selectedBon).toFixed(3) - factureD.totale).toFixed(3)}</h3> 
                                        <br />
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Button disabled={payBtnState ||!saveBtnState} fluid style={{height:'50px'}} className='border-div bg-danger text-white' onClick={() => SavePaymmentBons('Moins')}>  <Icon name='check' /> Payeé</Button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-12'>
                                    <h5>Imprimer</h5> 
                                    <div className='row'>
                                        <div className='col-9'><Button disabled={!saveBtnState || !payBtnState} fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={() =>PrintFunction('printFacture')}>  <Icon name='print' /> Imprimer </Button></div>
                                        <div className='col-3 text-center'><Button  fluid style={{height:'40px'}} className='rounded-pill bg-system-btn mb-3' onClick={() =>PrintFunction('openCaisse')}>  <Icon name='lock open' /> </Button></div> 
                                    </div>
                            </div>
                        </div>  
                    </div> 
                </div> 
        </>)
    }



    const CreditClientOld = () =>{
        return (<>
                <MainDataCard factureD={factureD} setFactureD={setFactureD} clientList={clientList}/>
                <div className='mt-5'>
                    <h5>Buttons</h5>
                    <div className='row mb-2'>
                        <div className='col-12 mb-3'>
                            <Button disabled={saveBtnState} className='rounded-pill bg-system-btn' fluid onClick={SaveFacture}><Icon name='save' /> Enregistrer <Loader inverted active={loaderState} inline size='tiny' className='ms-2'/></Button>
                        </div>
                        <div className='col-12'>
                            <Button disabled={!saveBtnState} className='rounded-pill btn-imprimer' onClick={(e) => PrintFunction('printFacture')} fluid><Icon name='print' /> Imprimer</Button>
                        </div>
                    </div>
                </div>
        </>)
    }
    /*4- Clients */
    const SelectClientCard = () =>{
        const ClientCardList = (props) =>{
            return(<>
                <div className='card p-3 mb-2 shadow-sm rounded' onClick={() => setClientFidelle(props.data)}>
                    <div className='row'>
                            <div className='col-4'>{props.data.CL_ID}</div> 
                            <div className='col-6'>{props.data.CL_Name}</div> 
                            <div className='col-2 align-self-center'><Button icon className='p-2 btn-system-bg' ><Icon name='check' /></Button></div> 
                    </div>
                </div>
            </>)
        }
        return(<>
            <div className='row'>
                <div className='col-3'>
                    <Modal
                        size='small'
                        closeIcon
                        dimmer='blurring'
                        trigger={<Button className=" bg-white border" size='large' fluid> <Icon name='list alternate outline' />  </Button>}
                        >
                        <Modal.Content scrolling className='d-block spesific-commp' style={{height: '100vh',}}>
                            <div className='p-2 text-start'>
                                {clientList.map((data, index) => <ClientCardList key={index} data={data} /> )}
                            </div>
                        </Modal.Content>
                    </Modal>
                </div>  
                <div className='col-9'><SelectClientFideliteCard clientFidele={clientFidele} setClientFidelle={setClientFidelle} clientList={clientList}/></div>  
                {/* <div className='col-9'><SelectClientCreditCard clientCredit={clientCredit} setClientCredit={setClientCredit} clientList={clientList}/></div>   */}
            </div>
            
        </>)
    }
    const CreditClient = () =>{
        return (<>
                <div className='row mb-2'>
                        
                        <div className='col-12 col-lg-6 mb-3 d-none'>
                            <h3>Credit</h3> 
                            <SelectClientCard />
                            <div className='mt-4'>
                                <h5>Client Info : </h5>
                                <div>Nom :  {clientCredit ? clientCredit.CL_Name : '' } </div>
                                <div>Phone :  {clientCredit ? clientCredit.Phone : '' } </div>
                                <div>ID :  {clientCredit ? clientCredit.CL_ID : '' } </div>
                            </div>
                            <h5>Enregistrer</h5>
                            <Button disabled={payBtnState ||!saveBtnState || !clientCredit} className='rounded-pill bg-system-btn mb-3' fluid style={{height:'50px',}} onClick={() => SaveClientCredit()}><Icon name='save' /> Enregistrer Credit<Loader inverted active={loaderState} inline size='tiny' className='ms-2'/></Button>
                            {/* <Button disabled={!payBtnState ||!saveBtnState} className='rounded-pill btn-imprimer' onClick={(e) => PrintFunction('printFacture')} fluid><Icon name='print' /> Imprimer</Button> */}
                        </div>

                        <div className='col-12 col-lg-6'>
                            {scanResult ?
                            (
                            <QrReader
                                    constraints={{ facingMode: 'environment' }}
                                    scanDelay={500}
                                    onResult={(result, error) => {
                                    if (!!result) {  setClientFidelle(clientList.find(obj => obj.CL_ID == result.text)) ; setScanResult(false) }
                                    if (!!error) { console.log(error); }
                                    }}
                                    style={{  width: "150px",height: "150px" }}
                            />
                            ) : (
                                <div className='text-center mt-4'>
                                    <Button onClick={() => setScanResult(true)}>Cliquer Pour Scanner</Button>
                                </div>
                            )}
                        </div> 

                        <div className='col-12 col-lg-6'>
                            <h3>Fidelité</h3> 
                            <SelectClientCard />
                            {/* <SelectClientFideliteCard clientFidele={clientFidele} setClientFidelle={setClientFidelle} clientList={clientList}/> */}
                            <div className='mt-4'>
                                <h5>Client Info : </h5>
                                <div>Nom :  {clientFidele ? clientFidele.CL_Name : '' } </div>
                                <div>Phone :  {clientFidele ? clientFidele.Phone : '' } </div>
                                <div>ID :  {clientFidele ? clientFidele.CL_ID : '' } </div>
                            </div>
                            <h5>Enregistrer</h5>
                            <Button disabled={!payBtnState ||!saveBtnState || !clientFidele || fideliteState} className='rounded-pill bg-system-btn mb-3' fluid style={{height:'50px',}} onClick={() => SaveClientFidelle()}><Icon name='save' /> Enregistrer Fidelité<Loader inverted active={loaderState} inline size='tiny' className='ms-2'/></Button>
                            {/* <Button  className='rounded-pill btn-imprimer' onClick={() => setClientFidelle()} fluid><Icon name='print' /> Nouveaux </Button> */}
                        </div>

                    </div>
        </>)
    }

    return (  <>
        <BackCard data={OneGConf.backCard.nv}/>
        <ProgressLoadingBar  display={loadingPage} />
        <br />
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 col-md-4'>
                    <TotaleCard />    
                    <div className='spesific-commp' style={{height: '70vh', overflow: 'scroll', overflowX:'hidden'}}>
                            {factureD.articles.map( (val) => <ArticleListCard key={val.id} dataA={val}/>)}
                            <br />
                        </div> 
                </div>
                <div className='col-12 col-md-8'>
                    {/* <Tab menu={{ widths: panes.length , pointing: true  }} panes={panes} /> */}
                    <Tab menu={{ widths: panes.length , pointing: true  , className: OneGConf.themeMode == 'dark' ? 'bg-dark-theme-1 text-white' : 'bg-ligth-theme-1' }} panes={panes} />
                </div>
            </div>
        </div>
        <FrameForPrint frameId='printFacture' src={`/Pr/CamSys/facture/${gettedFID}`} />
        {/* <FrameForPrint frameId='printFacture' src={`/Pr/caisse/facture/${toPrintFID}`} /> */}
        <FrameForPrint frameId='openCaisse' src={`/Pr/caisse/open`} />
    </>);
}


export default CaisseSimple;