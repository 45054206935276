import React from 'react'
import { BrowserRouter as Router, Routes,Route, Outlet} from "react-router-dom";
import { Navigate } from 'react-router-dom';

 
//main 
import LoginPage from '../../InterFaces/RouterOne/LoginPage'
import OneLandingPage from '../../InterFaces/RouterOne/OneLandingPage'
import UploadeCamionPage from '../../InterFaces/RouterOne/Upload/updatePage';
import OneGConf from '../../InterFaces/RouterOne/Assets/OneGConf';

 
// //Caisse Simple 
import CaissePage from "../../InterFaces/RouterOne/Caisse/caisseSimple"; 
import CaisseCodeBar from "../../InterFaces/RouterOne/Caisse/caisseCodeBar";
import CaisseStockStoped from "../../InterFaces/RouterOne/Caisse/CaisseStockStoped";
import CaisseStockPannier from "../../InterFaces/RouterOne/Caisse/caisseStockPannier";
import CaisseStock from "../../InterFaces/RouterOne/Caisse/caisseStock";

// Factures 
import MesFactures from '../../InterFaces/RouterOne/Factures/facture';
import FactureInfo from '../../InterFaces/RouterOne/Factures/factureInfo';

//Commandes
import CommandesInfo from '../../InterFaces/RouterOne/Commandes/commandesInfo';
import CommandesPage from '../../InterFaces/RouterOne/Commandes/commandesPage';
import AccepterCommande from '../../InterFaces/RouterOne/Commandes/accepterCommande'; 

// //Recette
import ClientListe from "../../InterFaces/RouterOne/Clients/clientList";
import ClientInfo from '../../InterFaces/RouterOne/Clients/clientPointage';


// //Recette
import Recette from "../../InterFaces/RouterOne/Recette/recette";
import DepenseRecette from '../../InterFaces/RouterOne/Recette/ajouterDepense';
import ImprimerRecette from '../../InterFaces/RouterOne/Recette/imprimerRecette';


import SettingPage from '../../InterFaces/RouterOne/Used/settingPage';
import UpdatePage from '../../InterFaces/RouterOne/Used/updatePage';



const RedirectingPage = () => {
    const OneIsLogged = localStorage.getItem(`${OneGConf.routerTagName}_LocalD`);
    return (<>
        {
            OneIsLogged ? <Navigate to={`/${OneGConf.routerName}/L`} /> : <Navigate to={`/${OneGConf.routerName}/logIn`}  />
        } 
</>);}

const routerOne = () => (
    <Route path="C" exact element={<Outlet />} >
            <Route path="" exact element={<RedirectingPage />} />
            <Route path="logIn" exact element={<LoginPage />} />
             <Route path="L" exact element={<Outlet />} >
                    <Route path="" exact element={<OneLandingPage />} />
                    <Route path="nv" exact element={<CaissePage />} />
                    <Route path="nvr" exact element={<CaisseCodeBar />} />
                    <Route path="nvsk" exact element={<CaisseStock />} />
                    <Route path="nvskp" exact element={<CaisseStockPannier />} />
                     
                    <Route path="cmd" exact element={<Outlet />} >
                        <Route path="" exact element={<CommandesPage />} />
                        <Route path="info/:CID" exact element={<CommandesInfo />} />
                        <Route path="accept" exact element={<AccepterCommande />} />
                    </Route>
                    
                    <Route path="cl" exact element={<Outlet />} >
                        <Route path="" exact element={<ClientListe />} />
                        <Route path="info/:CLID" exact element={<ClientInfo />} />
                    </Route>

                    <Route path="ft" exact element={<Outlet />} >
                        <Route path="" exact element={<MesFactures />} />
                        <Route path="info/:FID" exact element={<FactureInfo />} />
                    </Route>

                    <Route path="rt" exact element={<Outlet />} >
                        <Route path="" exact element={<Recette />} />
                        <Route path="depenses" exact element={<DepenseRecette />} />
                        <Route path="imprimer" exact element={<ImprimerRecette />} />
                    </Route>
                    
                    <Route path="up-s" exact element={<UploadeCamionPage />} />
                    <Route path="up" exact element={<UpdatePage />} />
                    <Route path="st" exact element={<SettingPage />} />
            </Route>
    </Route>
)

export default routerOne 